import React from 'react';
import { Container, Row, Col } from "react-bootstrap";
import styled from 'styled-components';
import { Title, Section, Box, Button, Span, Text } from "../../components/Core";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import { FaArrowRight } from "react-icons/fa";
import example from "../../assets/image/jpeg/examples.jpg";
 
// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';
import { device, breakpoints } from "../../utils";
import Newarrow from '../../assets/image/jpeg/enter.svg';

const Bookbutton = styled.button`
font-size: 16px;
padding: 12px 34px;
    background-color: #006b7b;
    display: block;
    color: #fff;
    border-radius: 32px;
    transition: .5s;
    border: none;
   

  @media screen and (max-width: 767px) {
    background-color: #006b7b;
    display: inline-block;
    padding: 12px 34px;
    font-size: 14px;
  }
`;

const Separator = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #ffffff;

  ::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: #f7f7fb;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }
`;

const SeparatorEnd = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #f7f7fb;

  ::before, ::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
  }

  ::before {
    top: 0;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }

  ::after {
    bottom: 0;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }
`;

const Iwrap = styled.div`
  display: flex;
  margin-bottom: 1em;
  align-items: center;

  @media screen and (max-width: 767px) {
    margin-bottom: 8px;
    justify-content: flex-start;
  }

  @media screen and (max-width: 991px) {
    margin-bottom: 8px;
    justify-content: center;
    text-align: center;
  }
`;

const Itext = styled.div`
  margin-top: 0px;
  margin-bottom: 5px;
  color: #1a1a1a;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;

  span{
    color:#00aec7;
  }

  @media screen and (max-width: 991px) {
    font-size: 12px;
  }
`;

const SecondText = styled.h3`
  font-size: 2.2em;
  margin-bottom: 20px;
  color: #1a1a1a;
  font-weight: 200;
  letter-spacing: 2px;

  @media screen and (max-width: 767px) {
    margin-bottom: 24px;
    font-size: 16px;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
  }
`;

export default function Eyefaq({setIsModalOpen}) {
    return (
        <>
        <Separator />
       
        <Section py={4} id="faq" bg="#f7f7fb" className="pb-md-5 mb pt-md-5">
          <Container className="pb-md-5 mb pt-md-5 pt-3 pb-3">
            <Row className="justify-content-center pb-4">
              <Col lg="12">

                <Iwrap>


                  <Itext as="h2">Questions about <span>Eyebrow Lift</span></Itext>
                </Iwrap>
                <SecondText>
                  Frequently Asked Questions

                </SecondText>

              </Col>
            </Row>


            <Row className="">


              <Col
                lg="12"
                md="6"
                className="mb-4"
                data-aos="fade-up"
                data-aos-duration="750"
                data-aos-once="false"
                data-aos-delay="50"
              >
                <Accordion allowZeroExpanded>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What happens on the day? </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        We start with a consultation to understand what you are looking to achieve, then the treatment
                        is done. We will give you our professional advice before going ahead with the treatment

                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>


                </Accordion>
              </Col>

              <Col
                lg="12"
                md="6"
                className="mb-4"
                data-aos="fade-up"
                data-aos-duration="750"
                data-aos-once="false"
                data-aos-delay="50"
              >
                <Accordion allowZeroExpanded classname="">

                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        How soon can I see results from the eyebrow lift treatment?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        You'll notice an instant transformation after just one treatment session.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <br />

                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        How long will the results of the eyebrow lift last?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Enjoy your rejuvenated look for up to 6 months, ensuring long-lasting elegance.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <br />

                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What is the cost of the eyebrow lift treatment?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The eyebrow lift treatment is priced at £340, offering affordable luxury for stunning results.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <br />

                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        How much time does the eyebrow lift procedure take?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Achieve your desired look in just 10 minutes, fitting seamlessly into your busy schedule.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <br />

                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        How soon can I return to my daily activities after the eyebrow lift?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        You can go back to work immediately after the treatment, with no downtime required.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <br />

                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Will the eyebrow lift treatment cause any pain?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        No, you can undergo the treatment without experiencing any discomfort.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <br />

                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Can the eyebrow lift treatment be customized to my specific needs?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Yes, we provide personalized consultations and treatment plans tailored to your individual
                        requirements.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <br />

                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What benefits does the eyebrow lift treatment offer?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Enhance the freshness and openness of your eyes, exuding confidence with every glance.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <br />

                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        How will the eyebrow lift treatment improve my appearance?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The treatment will give you a higher and more defined eyebrow arch, making your eyes appear
                        fresher and wider.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <br />

                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Can I trust the expertise of the practitioners performing the eyebrow lift treatment?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Absolutely, our experienced practitioners ensure exceptional results, leaving you satisfied with
                        your enhanced look.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>

              </ Col>


            </Row>


            <br />
            <div onClick={() => setIsModalOpen(true)}
                 activeClassName="active"
            >
              <Bookbutton>
                Book Appointment
              </Bookbutton>
            </div>


          </Container>
        </Section>

          <SeparatorEnd />
        </>

    );
}









      

      

     
